

<template>
  <div style="background-color:#f8f8f8;height:100vh">
    <div style="display: flex;  justify-content: center;padding-top:200px">
        <v-lazy-image style="width:258px;margin-left:-100px" src="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Fimg-404-error%402x.png?alt=media&token=ccd1dcba-de78-455f-afa6-3d2c475dedea"/>
    </div>
     <div style="text-align:center">
        <span class="not1"> 404 Not Found</span>
     </div>
     <div style="text-align:center;margin-bottom:36px">
         <span class="not2">
             찾을 수 없는 페이지에요.<br/>입력하신 주소가 맞는지 확인 해 주세요!
         </span>
     </div>
     <div class="not-div" @click="onBack()">
        <span class="not3">메인으로 돌아가기</span>
    </div>
  </div>
</template>


<script>
import VLazyImage from "v-lazy-image";
export default {
  components: {
    VLazyImage
  },
  methods: {
    onBack() {
      this.$router.push("/").catch(() => {});
    }
  }
};
</script>

<style>
.v-lazy-image {
  filter: blur(10px);
  transition: filter 0.7s;
}
.v-lazy-image-loaded {
  filter: blur(0);
}
.not1 {
  font-family: "Muli", sans-serif;
  font-size: 30px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.11px;
  color: #0264fb;
  padding-top: 12px;
}

.not2 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.67px;
  text-align: center;
  color: #444444;
  padding-top: 8px;
}
.not3 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  text-align: center;
  color: #ffffff;
}
.not-div {
  cursor: pointer;
  width: 200px;
  height: 60px;
  border-radius: 12px;
  margin: 0 auto;
  text-align: center;
  background-color: #0264fb;
  padding-top: 18px;
}
</style>
